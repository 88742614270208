.PrivacyPolicy-h1 {
    text-align: center;
    margin: 20px 0px 20px 0px;
}

.PrivacyPolicy {
 /*top,right,bottom,left*/
margin: 20px 16px 20px 20px;
}

.PrivacyPolicy-li {
    font-size: x-large;
}