.center {
    display: flex;

    /* 猜想：把该控件的起始位置从左上角移动到屏幕中间 */
    position: absolute;
    top: 50%;
    left: 50%;

    /* 起始位置在中间，内容会从中间开始开始绘制，要想让内容真正居中，必须转换坐标，从(-0.5, -0.5)开始 */
    transform: translate(-50%, -50%);
}


.horizontal-center {
    display: flex;
    align-items: center;
}