.upload-container {
    height: 100%;
    text-align: center;
    margin: 0;
    padding: 0;
    background-color: white;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
}

#btn-upload {

}

/*.Upload div {*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    text-align: center;*/
/*}*/

/*.Upload input {*/
/*    font-size: calc(10px + 2vmin);*/
/*}*/

/*.Upload button {*/
/*    font-size: calc(10px + 2vmin);*/
/*}*/